import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Joyride, { CallBackProps, STATUS, Step as JoyrideStep } from 'react-joyride';
import { useLocation, useHistory } from 'react-router-dom';
import { useTour } from '@reactour/tour';

interface Step extends JoyrideStep {
  path: string;
}

const Tour: React.FC = () => {
  const [run, setRun] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const { setIsOpen } = useTour();

  // Tüm adımları tanımla
  const steps = useMemo<Step[]>(() => [
    {
      target: '.theme-buttons',
      content: 'Tema seçeneklerini buradan değiştirebilirsiniz.',
      disableBeacon: true,
      path: '/dashboard'
    },
    {
      target: '.test',
      content: 'Tema seçeneklerini buradan değiştirebilirsiniz.',
      disableBeacon: true,
      path: '/dashboard'
    },
    {
      target: '.step-lines-tables',
      content: 'Bu bölümde BIST sembollerinin sentiment değerlerini görebilirsiniz.',
      placement: 'bottom',
      disableBeacon: true,
      path: '/bist/lines'
    },
    {
      target: '.step-lines-graph',
      content: 'Bu bölümde BIST sembollerinin teknik sentiment grafiklerini görebilirsiniz.',
      placement: 'bottom',
      disableBeacon: true,
      path: '/bist/lines'
    },
    {
      target: '.follower-container',
      content: 'BIST Follower sayfasında takipçi analizlerini görüntüleyebilirsiniz.',
      disableBeacon: true,
      path: '/bist/follower'
    }
  ], []);

  // Hedef elementlerin varlığını kontrol et
  const checkTargetElements = useCallback(() => {
    const currentStep = steps[currentStepIndex];
    if (!currentStep) return false;

    const targetElement = document.querySelector(currentStep.target as string);
    return !!targetElement;
  }, [steps, currentStepIndex]);

  // Turu başlatma fonksiyonu
  const startTour = useCallback(() => {
    console.log('Starting tour...');
    setCurrentStepIndex(0);

    // Hedef elementlerin yüklenmesini bekle
    const checkElements = () => {
      if (checkTargetElements()) {
        setRun(true);
        setIsOpen(true);
      } else {
        setTimeout(checkElements, 100);
      }
    };

    checkElements();
  }, [setIsOpen, checkTargetElements]);

  // İlk yükleme için useEffect
  useEffect(() => {
    const isTourCompleted = localStorage.getItem('tourCompleted');
    if (!isTourCompleted) {
      startTour();
    }
  }, [startTour]);

  // Sayfa değişikliklerini izle
  useEffect(() => {
    if (!run) return;

    // Mevcut sayfadaki ilk adımı bul
    const currentPathIndex = steps.findIndex(step => step.path === location.pathname);
    if (currentPathIndex !== -1) {
      // Hedef elementlerin yüklenmesini bekle
      const checkElements = () => {
        const targetElement = document.querySelector(steps[currentPathIndex].target as string);
        if (targetElement) {
          setCurrentStepIndex(currentPathIndex);
        } else {
          setTimeout(checkElements, 100);
        }
      };

      checkElements();
    }
  }, [location.pathname, run, steps]);

  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    const { status, index, type } = data;

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
      setIsOpen(false);
      localStorage.setItem('tourCompleted', 'true');
      // Reset scroll position when tour ends
      window.scrollTo(0, 0);
    }

    if (type === 'step:after') {
      const currentStep = steps[index];
      const nextStep = steps[index + 1];

      if (nextStep) {
        if (currentStep.path !== nextStep.path) {
          // Farklı sayfaya geçiş
          setRun(false);
          history.push(nextStep.path);
          setTimeout(() => {
            setRun(true);
          }, 100);
        } else {
          // Aynı sayfa içinde bir sonraki adıma geç
          setCurrentStepIndex(index + 1);
        }
      } else {
        // Son adımda turu bitir
        setRun(false);
        setIsOpen(false);
        localStorage.setItem('tourCompleted', 'true');
        // Reset scroll position when tour ends
        window.scrollTo(0, 0);
      }
    }
  }, [steps, history, setIsOpen]);

  // Turu başlatma fonksiyonunu global olarak erişilebilir yap
  useEffect(() => {
    (window as any).startTour = startTour;
    return () => {
      delete (window as any).startTour;
    };
  }, [startTour]);

  return (
    // <Joyride
    //   callback={handleJoyrideCallback}
    //   continuous
    //   hideCloseButton
    //   run={run}
    //   scrollToFirstStep
    //   showProgress
    //   showSkipButton
    //   steps={steps}
    //   stepIndex={currentStepIndex}
    //   styles={{
    //     options: {
    //       arrowColor: '#ffffff',
    //       backgroundColor: '#ffffff',
    //       overlayColor: 'rgba(0, 0, 0, 0.5)',
    //       primaryColor: '#000',
    //       textColor: '#333',
    //       zIndex: 1000,
    //     },
    //   }}
    // />
    <></>
  );
};

export default Tour;
