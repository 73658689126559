import { useEffect, useState } from "react";
import { SYMBOLS_DICT } from "./symbols";

export const MessageText = (props: any) => {
  const [messageItems, setMessageItems] = useState<any[]>([]);
  const imgSize = props.imgSize ?? "";
  const imgBorderRadius = props.imgBorderRadius ?? "";
  useEffect(() => {
    if (props.message == null) return;
    let canStartStock = true;
    let charStartIndex = 0;
    let symbols: any = {};
    const maxCharToScan = props.maxCharToScan ?? props.message.text.length;
    for (let i = 0; i < maxCharToScan; i++) {
      const isChar = /[a-zA-ZçÇğĞıİöÖüÜ]/.test(props.message.text[i]);

      if ((!isChar || i === props.message.text.length - 1) && canStartStock) {
        const end =
          isChar === false
            ? i
            : i === props.message.text.length - 1
              ? i + 1
              : i;
        const word = props.message.text.substring(charStartIndex, end);
        if (SYMBOLS_DICT[word]) {
          symbols[charStartIndex] = end;
        }
        canStartStock = false;
      }
      if (isChar && !canStartStock) {
        charStartIndex = i;
        canStartStock = true;
      }
      if (props.singleLine && props.message.text[i] == "\n") break;
    }
    let items: any = [""];
    for (let i = 0; i < maxCharToScan; i++) {
      if (props.singleLine && props.message.text[i] == "\n") break;
      if (!symbols[i]) {
        if (typeof items[items.length - 1] !== "string") items.push("");

        items[items.length - 1] += props.message.text[i];
      } else {
        items.push({ symbol: props.message.text.substring(i, symbols[i]) });
        i = symbols[i] - 1;
      }
    }
    setMessageItems(items);
  }, [props.message]);

  return (
    <div className="message-text-line">
      {messageItems.map((item) => {
        if (item.symbol)
          return (
            <span
              className="message-symbol"
              onClick={() => {
                props.setSelectedSymbol(item.symbol);
              }}
            >
              <img
                src={`https://storage.googleapis.com/sentiment-algo-pro-stocks/${item.symbol?.trim()}.png?v=5`}
                style={{
                  width: imgSize,
                  height: imgSize,
                  borderRadius: imgBorderRadius,
                  marginRight: imgSize / 4,
                }}
              />
              {item.symbol}
            </span>
          );
        else return item;
      })}
    </div>
  );
};
