export const AgreementContent = {
    membershipAgreementText:
        <>
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>1.TARAFLAR</h5>
            <b>a) </b> Sentiment Algo Pro akıllı telefon, masaüstü ve web uygulamasının (“Uygulama” veya “Sentiment Algo Pro
            Uygulaması”) faaliyetlerini yürüten Çifte Havuzlar Mah. Eski Londra Asfaltı Cad. Kuluçka Mrk. D2 Blok No:151/F İç Kapı No:203 ESENLER/İSTANBUL
            adresinde mukim ZEPHLEX BİLGİ TEKNOLOJİLERİ EĞİTİM
            VE DANIŞMANLIK ANONİM ŞİRKETİ (Bundan sonra “uygulama sahibi şirket” veya “Şirket” olarak
            anılacaktır)
            <br />
            <b>b) </b>Sentiment Algo Pro Uygulamasına üye olan internet kullanıcısı (“Üye/Kullanıcı”)
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>2.SÖZLEŞMENİN KONUSU</h5>
            İşbu Sözleşme'nin konusu Üyenin ZEPHLEX BİLGİ TEKNOLOJİLERİ EĞİTİM VE DANIŞMANLIK ANONİM
            ŞİRKETİ nin sahip olduğu Sentiment Algo Pro Uygulamasından faydalanma şartlarının belirlenmesidir.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>3.TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ</h5>
            <b>3.1</b> Üye, Sentiment Algo Pro Uygulamasına üye olurken verdiği kişisel ve diğer sair bilgilerin (ad, soyad,
            adres, telefon, TC kimlik no, e-posta adresi, banka hesap bilgileri vb. bilgiler) doğru kendisine ait
            olduğunu, ZEPHLEX BİLGİ TEKNOLOJİLERİ EĞİTİM VE DANIŞMANLIK ANONİM ŞİRKETİ nin, bu bilgilerin
            gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları aynen ve derhal tazmin edeceğini beyan ve
            taahhüt eder. Uygulama sahibi şirket bu bilgilerin doğru olmadığını ve/veya bu bilgilerin, üye girişi
            yaparak işlem yapan kişiye ait olmadığını tespit etmesi durumunda Üye'ye bilgi vermeden, işlem iptali
            ve/veya üyelik iptali yapabilir.
            <br />
            <b>3.2.</b> Üye, uygulama sahibi şirket tarafından kendisine verilmiş olan şifreyi başka kişi ya da kuruluşlara
            veremez, üyenin söz konusu şifreyi kullanma hakkı bizzat kendisine aittir. Bu sebeple doğabilecek tüm
            sorumluluk ile üçüncü kişiler veya yetkili merciler tarafından uygulama sahibi şirkete karşı ileri
            sürülebilecek tüm iddia ve taleplere karşı, uygulama sahibinin söz konusu izinsiz kullanımdan
            kaynaklanan her türlü tazminat ve sair talep hakkı saklıdır.
            <br />
            Üye, uygulamayı kullanırken, kendisine ait Üye adı ve/veya şifrelerini öğrenmek için üçüncü kişilerin
            çeşitli yazılımlar ve virüs programları geliştirebildiğini bilmekte olup, bu programların ve virüslerin zarar
            verici etkilerinden korunmak amacıyla, işlemleri gerçekleştirmek için kullandığı donanımda/yazılımda
            anti virüs programları da dahil olmak üzere, teknolojik gelişmeler çerçevesinde gerekli her türlü
            güvenlik önlemini alacağını, Kullanıcı/Üye Adı ve Şifresini ve uygulamadan yararlanmak için gerekli olan
            sair bilgilerini gizli tutacağını, koruyacağını, hiçbir şekilde üçüncü kişilerle paylaşmayacağını ve üçüncü
            kişilerin bu bilgilere ulaşmasını engellemek için gerekli her türlü tedbiri alacağını, güvenli olduğundan
            emin olmadığı donanımlarda işlem yapmayacağını, aksi halde doğacak tüm zararlardan kendisinin
            sorumlu olacağını, bu husustaki ihtilaflarda Uygulama Sahibi şirketin taraf olmayacağını kabul ve beyan
            eder.
            <br />
            Kullanıcı Şifresi kullanılarak uygulama üzerinden gerçekleştirilen/gerçekleştirilecek her türlü işlem,
            Kullanıcı/Üye tarafından gerçekleştirilmiş sayılacak ve Kullanıcı açısından bağlayıcı olacaktır. Bu
            kapsamda, Üye, Kullanıcı Şifresini hiçbir şekilde 3. Kişiler ile paylaşmayacağını ve kendisine sunulan
            hizmeti 3. Kişilere ücret karşılığında ve/veya ücretsiz olarak vermeyeceğini kabul, beyan ve taahhüt
            eder. Uygulama Sahibi Şirketin hizmetin 3. Kişilere verildiğini tespit etmesine yönelik kanaati oluşması
            veya tespit etmesi durumunda, Kullanıcı'ya sunulan hizmeti hiçbir bildirimde bulunmaksızın
            sonlandırma hakkı bulunduğunu ve bu halde Uygulama Sahibi Şirketin tazminat dahil her türlü yasal
            hakkını kullanabileceğini kabul, beyan ve taahhüt eder.
            <br />
            <b>3.3.</b> Üye, Sentiment Algo Pro Uygulamasını kullanırken yasal mevzuat hükümlerine riayet etmeyi ve bunları
            ihlal etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler
            tamamen ve münhasıran üyeyi bağlayacaktır. Üye, diğer internet kullanıcılarının yazılımlarına ve
            verilerine izinsiz olarak ulaşmamayı veya bunları kullanmamayı kabul etmiştir. Aksi takdirde, bundan
            doğacak hukuki ve cezai sorumluluklar tamamen üyeye aittir.
            <br />
            <b>3.4.</b> Uygulama Sahibi şirketin yükümlülüğü, üyelere uygulama tahtındaki hizmetin sunulması ile
            sınırlıdır. Uygulama sahibi şirket, uygulamadan alınan hizmetlerden kaynaklanan işlemlere yönelik
            denetim yapmakla sorumlu değildir ve herhangi bir Üye'nin sunulan hizmetler sonucu mutlaka kar
            edeceğini/para kazanacağını garanti etmemekte ve bu nedenle herhangi bir sorumluluk kabul
            etmemektedir.
            <br />
            Uygulama kapsamındaki bilgi ve veriler, hiçbir şekil ve surette Uygulama Sahibi Şirket'in herhangi bir
            taahhüdünü tazammun etmediğinden, bu bilgilere istinaden her Üyeler tarafından alınacak kararlar,
            varılacak sonuçlar ve oluşabilecek her türlü riskler bu Üyelere ait olacaktır. Hiçbir şekil ve surette ve
            her ne nam altında olursa olsun, Üyelerin uğrayabileceği her türlü doğrudan ve/veya dolaylı zarar,
            maddi ve/veya manevi kâr mahrumiyeti, velhasıl her ne nam altında olursa olsun uğrayabileceği tüm
            zararlardan hiçbir şekil ve surette Uygulama Sahibi Şirket sorumlu tutulamayacak ve hiçbir şekil ve
            surette her ne nam altında olursa olsun Uygulama Sahibi Şirket' den talepte bulunulmayacaktır.
            <br />
            Üye, Sentiment Algo Pro Uygulaması içindeki faaliyetlerinde, uygulamanın herhangi bir bölümünde veya
            iletişimlerinde genel ahlaka ve adaba aykırı, kanuna aykırı, 3. Kişilerin haklarını zedeleyen, yanıltıcı,
            saldırgan, müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına aykırı, yasa dışı faaliyetleri
            teşvik eden içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan
            tamamen kendisi sorumludur ve bu durumda 'Uygulama yetkilileri, bu tür hesapları askıya alabilir, sona
            erdirebilir, yasal süreç başlatma hakkını saklı tutar. Bu sebeple mahkemelerden veya idari mercilerden
            etkinlik veya kullanıcı hesapları ile ilgili bilgi talepleri gelirse paylaşma hakkını saklı tutar.
            3.5. Sentiment Algo Pro Uygulamasında üyeler tarafından beyan edilen, yazılan, kullanılan fikir ve
            düşünceler, tamamen üyelerin kendi kişisel görüşleridir ve görüş sahibini bağlar. Bu görüş ve
            düşüncelerin uygulama sahibi şirket hiçbir ilgi ve bağlantısı yoktur. Uygulama sahibi şirket, üyenin
            beyan edeceği fikir ve görüşler nedeniyle üçüncü kişilerin uğrayabileceği zararlardan ve üçüncü kişilerin
            beyan edeceği fikir ve görüşler nedeniyle üyenin uğrayabileceği zararlardan dolayı herhangi bir
            sorumluluğu bulunmamaktadır.
            <br />
            <b>3.6.</b> Uygulama sahibi şirket, üye verilerinin yetkisiz kişilerce okunmasından ve üye yazılım ve verilerine
            gelebilecek zararlardan dolayı sorumlu olmayacaktır. Üye, Sentiment Algo Pro Uygulamasının
            kullanılmasından dolayı uğrayabileceği herhangi bir zarar yüzünden uygulama sahibi şirketten tazminat
            talep etmemeyi peşinen kabul etmiştir.
            <br />
            <b>3.7</b> Uygulama sahibi şirket, Uygulamanın altyapısını, uygulamalarını, şeklini ve içeriğini değiştirmekte,
            daraltmakta veya genişletmekte, yeni ürünler/hizmetler ekleyip mevcut ürünlerin/hizmetlerin bir veya
            birkaçını çıkartmakta serbesttir. Üye, söz konusu değişikliklerin uygulamaya yansıtıldığı tarihten
            itibaren derhal uygulanmaya başlayacağını kabul, beyan ve taahhüt eder.
            <br />
            <b>3.8</b> İşbu üyelik sözleşmesi içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal
            nedeniyle cezai ve hukuki olarak şahsen sorumlu olup, Uygulama sahibi şirketi bu ihlallerin hukuki ve
            cezai sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın mahkemelere intikal etmesi
            halinde uygulama sahibi şirketin, üyeye karşı üyelik sözleşmesine uyulmamasından dolayı tazminat
            talebinde bulunma hakkı saklıdır.
            <br />
            <b>3.9</b> Üye, kendisine iletişim hizmeti sağlayan internet servis sağlayıcılarda, mobil iletişim hizmetlerinde,
            Türkiye'nin veya işlem gerçekleştirmiş olduğu ülkenin ulusal ve/veya uluslararası internet
            bağlantılarında, mobil iletişim hizmetlerinde yaşanabilecek sorunlardan, internet hizmetlerinin
            kesintiye uğramasından, performans kayıplarından uygulama sahibi şirketi sorumlu tutamaz.
            Uygulama sahibi şirket, teknik nedenlerden ve/veya mücbir sebeplerden ya da kendisine kusur
            atfedilmesi mümkün olmayan diğer sebeplerden dolayı Üyeler tarafından Uygulamaya ulaşılamaması,
            işlemlerin kısmen veya tamamen veya zamanında yerine getirilmemesi ya da hatalı yerine
            getirilmesinden sorumlu değildir.
            <br />
            <b>3.10</b> Sentiment Algo Pro Uygulaması yazılım ve tasarımı uygulama sahibi şirket mülkiyetinde olup, bunlara
            ilişkin telif hakkı ve/veya diğer fikri ve sınai mülkiyet hakları ilgili kanunlarca korunmakta olup, bunlar
            üye tarafından izinsiz kullanılamaz, iktisap edilemez ve değiştirilemez. Bu Uygulamasında adı geçen
            başkaca şirketler ve ürünleri sahiplerinin ticari markalarıdır ve ayrıca fikri mülkiyet hakları kapsamında
            korunmaktadır.
            <br />
            <b>3.12</b> Sentiment Algo Pro Uygulamasının virüs ve benzeri amaçlı yazılımlardan arındırılmış olması için
            mevcut imkanlar dahilinde tedbir alınmıştır. Bunun yanında nihai güvenliğin sağlanması için
            kullanıcının, kendi virüs koruma sistemini tedarik etmesi ve gerekli korunmayı sağlaması
            gerekmektedir. Bu bağlamda üye Sentiment Algo Pro Uygulamasına girmesiyle, kendi yazılım ve işletim
            sistemlerinde oluşabilecek tüm hata ve bunların doğrudan ya da dolaylı sonuçlarından kendisinin
            sorumlu olduğunu kabul etmiş sayılır.
            <br />
            <b>3.14</b> Uygulama sahibi şirket, Uygulamanın içeriğini dilediği zaman değiştirme, kullanıcılara sağlanan
            herhangi bir hizmeti değiştirme ya da sona erdirme veya Sentiment Algo Pro Uygulamasına kayıtlı kullanıcı
            bilgi ve verilerini silme hakkını saklı tutar. Uygulama sahibi şirketin, her zaman tek taraflı olarak
            gerektiğinde üyenin üyeliğini silme, müşteriye ait dosya, belge ve bilgileri silme hakkı vardır. Üye işbu
            tasarrufu önceden kabul eder. Bu durumda, uygulama sahibi şirketin hiçbir sorumluluğu yoktur.
            <br />
            <b>3.15</b> Uygulama sahibi şirket, üyelik sözleşmesinin koşullarını hiçbir şekil ve surette ön ihbara ve/veya
            ihtara gerek kalmaksızın her zaman değiştirebilir, güncelleyebilir veya iptal edebilir. Değiştirilen,
            güncellenen ya da yürürlükten kaldırılan her hüküm, yayın tarihinde tüm üyeler bakımından hüküm
            ifade edecektir.
            <br />
            <b>3.16</b> Uygulama sahibi şirket, bu Sözleşme ile belirlenen amaç ve kapsam dışında da üyelik aşamasında
            talep edilen ad-soyad, telefon numarası, e-posta adresi, adres, gibi bilgileri yürürlükte bulunan
            mevzuatta öngörülen koşullara tabi şekilde ve gerektirmesi halinde onay ve/veya açık rızalarını almak
            suretiyle sms, e-posta, uygulama içi bilgilendirmeler ve benzeri yöntemlerle, tanıtım ve bilgilendirme
            amaçlı iletişim faaliyetleri, araştırma, pazarlama faaliyetleri ve istatistikî analizler yapmak amacıyla
            veya gerektiğinde üyeler ile temas kurmak, süreçlerini iyileştirme amaçlı araştırmalar yapmak, veri
            tabanı oluşturmak ve pazar araştırmaları yapmak için kullanabilir. Üye, işbu Sözleşme'yi onaylayarak,
            belirtilen hususlara da onay vermektedir.
            <br />
            Üye'nin kişisel veri teşkil edebilecek ve kendisi tarafından Uygulamaya girilen verileri Uygulama Sahibi
            Şirket tarafından uygulamanın fonksiyonlarının kullandırılması amacıyla toplanarak, amaçla sınırlı
            olmak üzere Kişisel Verilerin Korunması Politikası çerçevesinde işlenmektedir. Üye, bu verilerin
            toplanmasının, işlenmesinin ve aktarılmasının, öncelikle Uygulama Sahibi Şirketin sisteminin
            işleyebilmesinin zorunlu bir unsuru olduğunu, aksi takdirde Uygulamadan sunulan hizmetlerden ve
            özelliklerden yararlanmalarının mümkün olmadığını kabul eder. Üye'nin yukarıda bahsi geçen kişisel
            bilgilerine ilişkin her türlü işlem, Uygulama Sahibi Şirket tarafından, kişisel verilerin korunmasına ilişkin
            mevzuat kapsamına uygun olarak gerçekleştirilecektir.
            <br />
            <b>3.17</b> Taraflar, üyelere ait tüm bilgisayar kayıtlarının tek ve gerçek münhasır delil olarak, HUMK madde
            287'ye uygun şekilde esas alınacağını ve söz konusu kayıtların bir delil sözleşmesi teşkil ettiği hususunu
            kabul ve beyan eder.
            <br />
            <b>3.18</b> Uygulama sahibi şirket, iş bu üyelik sözleşmesi uyarınca, üyelerinin kendisinde kayıtlı elektronik
            posta adreslerine bilgilendirme e-postaları ve cep telefonlarına bilgilendirme SMS'leri gönderme
            yetkisine sahip olmakla beraber, üye işbu üyelik sözleşmesini onaylamasıyla beraber bilgilendirme
            maillerinin elektronik posta adresine ve bilgilendirme SMS'lerinin cep telefonuna gönderilmesini kabul
            etmiş sayılacaktır.
            <br />
            <b>3.19</b> Uygulama Sahibi Şirket, Uygulamada reklam alanı olarak belirleyeceği tüm alanlarda kendisinin,
            iştiraklerinin, grup şirketlerinin veya iş ortaklarının veya anlaşma sağladığı sair şirket ve kuruluşların
            reklamını yayınlayabilir.
            <br />
            <b>3.20</b> Bu sözleşmeyle sağlanmış olan hak ve sorumluluklar hiçbir şekilde bir başka şahıs veya tüzel kişiye
            ücretli veya ücretsiz devredilemez, kiralanamaz, kullandırılamaz.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>4.FİKRİ MÜLKİYET HAKLARI</h5>
            <b>4.1</b> İşbu Uygulama'da yer alan unvan, işletme adı, marka, patent, logo, tasarım, bilgi ve yöntem gibi
            tescilli veya tescilsiz tüm fikri mülkiyet hakları uygulama işleteni ve sahibi firmaya veya belirtilen
            ilgilisine ait olup, ulusal ve uluslararası hukukun koruması altındadır. İşbu Uygulama'nın ziyaret
            edilmesi veya bu Uygulama'daki hizmetlerden yararlanılması söz konusu fikri mülkiyet hakları
            konusunda hiçbir hak vermez.
            <br />
            <b>4.2</b> Uygulama 'da yer alan bilgiler hiçbir şekilde çoğaltılamaz, türetilemez yayınlanamaz,
            kopyalanamaz, sunulamaz ve/veya aktarılamaz. Uygulama'nın bütünü veya bir kısmı diğer bir telefon,
            web, internet tabanlı olan veya olmayanlar dahil hiçbir yöntem ve şekilde , izinsiz olarak kullanılamaz.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>5.SÖZLEŞMENİN FESHİ</h5>
            <b>5.1</b> İşbu sözleşme üyenin üyeliğini iptal etmesi veya uygulama sahibi şirket tarafından üyeliğinin iptal
            edilmesine kadar yürürlükte kalacaktır.
            <br />
            <b>5.2</b> Uygulama sahibi şirket, üyenin üyelik sözleşmesinin herhangi bir hükmünü ihlal etmesi durumunda
            bildirimsiz ve tazminatsız şekilde tek taraflı olarak üyenin üyeliğini iptal ederek sözleşmeyi tek taraflı
            olarak feshetme hakkına sahiptir.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>6.MÜCBİR SEBEP</h5>
            Tarafların kontrolünde olmayan; tabii afetler, yangın, patlamalar, iç savaşlar, savaşlar, ayaklanmalar,
            halk hareketleri, seferberlik ilanı, grev, lokavt ve salgın hastalıklar, altyapı ve internet arızaları, elektrik
            kesintisi gibi sebeplerden ("Mücbir Sebep") dolayı sözleşmeden doğan yükümlülükler taraflarca ifa
            edilemez hale gelirse, taraflar bundan sorumlu değildir. Bu sürede Taraflar'ın işbu Sözleşme'den doğan
            hak ve yükümlülükleri askıya alınır.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>7.TEBLİGAT</h5>
            İşbu Sözleşme ile ilgili taraflara gönderilecek olan tüm bildirimler, Şirketin bilinen e -posta adresi ve
            kullanıcının üyelik formunda belirttiği e-posta adresi vasıtasıyla yapılacaktır. Üye, üye olurken belirttiği
            adresin geçerli tebligat adresi olduğunu, değişmesi durumunda 5 gün içinde yazılı olarak diğer tarafa
            bildireceğini, aksi halde bu adrese yapılacak tebligatların geçerli sayılacağını kabul eder.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>8.İHTİLAFLARIN HALLİ</h5>
            İşbu Sözleşme'ye ilişkin ihtilaflarda İstanbul Çağlayan Mahkemeleri ve İcra Daireleri yetkili olup,
            ihtilaflara Türk hukuku uygulanacaktır.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>9.YÜRÜRLÜK</h5>
            Üyenin, üyelik kaydı yapması üyenin bu Sözleşmede yer alan tüm maddeleri okuduğu ve üyelik
            sözleşmesinde yer alan maddeleri kabul ettiği anlamına gelir. İşbu Sözleşme üyenin üye olması anında
            akdedilmiş ve karşılıklı olarak yürürlüğe girmiştir.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>10. KİŞİSEL VERİLERİN KORUNMASI</h5>
            6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") hükümleri gereği kimliğinizi belirli veya
            belirlenebilir kılan her türlü bilginiz, özel nitelikli kişisel verileriniz de dahil olmak üzere, Uygulama
            Sahibi Şirket tarafından sunulan veya talep edilen başkaca ürün ve hizmetleri sunabilmek, tarafınızla
            imza edilen sözleşmenin gereğini yerine getirmek, güvenlik ile suçun önlenmesi gibi meşru
            menfaatlerimizi yerine getirmek amacıyla Kişisel Veri olarak Uygulama Sahibi Şirket tarafından
            toplanır, saklanır, işlenir. "Kişisel Verilerinizin işlenmesi" bu verilerin elde edilmesi, kaydedilmesi,
            depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması,
            devralınması, elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi
            veriler üzerinde gerçekleştirilen her türlü işlemi ifade etmektedir. Yukarıda sıralanan amaçlarla
            Uygulama Sahibi Şirket nezdinde bulunan kişisel verileriniz güvenle saklanır. Üye, bu Sözleşmeyi imza
            ederek, kişisel verilerinin toplanmasını, saklanmasını ve işlenmesini ve gerektiğinde 3. Kişilerle
            paylaşılmasını kabul ve beyan etmektedir.
            <br />
            Yukarıda sıralanan amaçlarla Uygulama Sahibi Şirket nezdinde bulunan kişisel veriler güvenle saklanır
            ve yasal çerçeve dışındaki 3. kişilere açıklanmaz. Sair mevzuat hükümlerinin zorunlu kıldığı/izin verdiği
            kişi, kurum ve/veya kuruluşlara, kişisel verileri alma yetkisi bulunan kamu tüzel kişilerine, otoritelere,
            aracılık/acentelik sıfatıyla faaliyet yürütülen şirketlere, faaliyetlerimizi yürütmek üzere hizmet alınan
            üçüncü taraflara örneğin yurtiçi/yurtdışı bankalar, hizmet/destek/danışmanlık alınan
            yurtiçi/yurtdışı/uluslararası kuruluşlara hukuki zorunluluklar nedeniyle ancak yasal sınırlamalar
            çerçevesinde kişisel verilerinizi aktarabilecektir.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>11. YASAL ÇEKİNCE</h5>
            <span style={{ fontWeight: 600 }}>
                BU UYGULAMADA YER ALAN HER TÜRLÜ VERİ, ANALİZ, GRAFİK, YORUM, BİLGİ, DEĞERLENDİRME,
                BİLGİLENDİRME, İSTATİSTİKİ ŞEKİL VE BİLGİLER (“VERİLER”) ZEPHLEX BİLGİ TEKNOLOJİLERİ EĞİTİM VE
                DANIŞMANLIK A.Ş TARAFINDAN ÜRETİLEN SENTİMENT ALGO SİSTEMİ KULLANILARAK
                OLUŞTURULMUŞTUR. SUNULAN VERİLERİN DOĞRULUĞU VE BUNLARIN YATIRIM KARARLARINA
                UYGUNLUĞU TARAFIMIZCA GARANTİ EDİLMEMEKTEDİR. BU BİLGİLER BELLİ BİR GETİRİNİN
                SAĞLANMASINA YÖNELİK OLARAK VERİLMEMEKTE OLUP ALIM-SATIM KARARINI
                DESTEKLEYEBİLECEK YETERLİ BİLGİLER DEĞİLDİR. HERHANGİ BİR YATIRIM ARACININ ALIM-SATIM
                ÖNERİSİ YA DA GETİRİ VAADİ OLARAK YORUMLANMAMALIDIR. BU VERİLER MALİ DURUMUNUZ İLE
                RİSK VE GETİRİ TERCİHLERİNİZE UYGUN OLMAYABİLİR. SADECE BURADA YER ALAN VERİLERE
                DAYANARAK YATIRIM KARARI VERİLMESİ BEKLENTİLERİNİZE UYGUN SONUÇLAR DOĞURMAYABİLİR.
                BU NEDENLE UYGULAMADA YER ALAN VERİLERDEKİ HATALARDAN EKSİKLİKLERDEN YA DA BU
                BİLGİLERE DAYANILARAK YAPILAN İŞLEMLERDEN, YORUM VE BİLGİLERİN KULLANILMASINDAN
                DOĞACAK HER TÜRLÜ MADDİ/MANEVİ ZARARLARDAN VE HER NE ŞEKİLDE OLURSA OLSUN ÜYE
                VE/VEYA ÜÇÜNCÜ KİŞİLERİN UĞRAYABİLECEĞİ HER TÜRLÜ DOĞRUDAN VE/VEYA DOLAYLI
                ZARARLARDAN DOLAYI ZEPHLEX BİLGİ TEKNOLOJİLERİ EĞİTİM VE DANIŞMANLIK A.Ş İLE BAĞLI
                KURULUŞLARI, ÇALIŞANLARI, YÖNETİCİLERİ VE ORTAKLARI SORUMLU TUTULAMAZ. BURADA YER
                ALAN VERİLER YATIRIM DANIŞMANLIĞI KAPSAMINDA DEĞİLDİR.
            </span>
        </>,
    kvkkText:
        <>
            ZEPHLEX BİLGİ TEKNOLOJİLERİ EĞİTİM VE DANIŞMANLIK A.Ş.olarak (“ZEPHLEX”) işlediğimiz kişiselverilerinizin korunmasına önem veriyoruz. Bu nedenle kişisel verilerinizin 6698 sayılı KişiselVerilerin Korunması Kanunu’na (“KVKK”) uygun işlenerek, muhafaza edilmesi hususundagereken bütün gayret ve özeni gösteriyoruz. Veri Sorumlusu sıfatıyla ZEPHLEX, kişiselverilerinizle ilgili bütün işlemlerde verilerinizin korunması için gereken idari ve tekniktedbirleri almakta, KVKK doğrultusunda kişisel verilerinizi aşağıda açıklanan şartlarda vemevzuatta öngörülen sınırlar dâhilinde işlemektedir.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>Veri Sorumlusunun Kimliği ve İletişim Bilgileri</h5>
            <br />
            <strong>Veri Sorumlusu:</strong> ZEPHLEX BİLGİ TEKNOLOJİLERİ EĞİTİM VE DANIŞMANLIK A.Ş. (VKN:9971462832)
            <br />
            <strong>Adres:</strong> Çifte Havuzlar Mah. Eski Londra Asfaltı Cad. Kuluçka Mrk. D2 Blok No:151/1F İç Kapı No:203 Esenler, İstanbul
            <br />
            <strong>Telefon</strong>: <a>0212 939 76 51</a>
            <br />
            <strong>E-posta</strong>: info@zephlex.com
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>Kişisel Verilerinizin İşlenme Amaçları</h5>
            <br />
            Aşağıda belirtilen kişisel verileriniz yine aşağıda belirtilen amaçlarla sınırlı olmak üzere Şirketimiz tarafından işlenmektedir.
            <br />
            Kimlik bilgisi, İletişim bilgisi, İşlem bilgisi, İşletim sistemi bilgisi, Mobil ID erişim bilgileri (IP bilgileri)
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>Kişisel verileriniz;</h5>
            <br />
            -Kanunlarda öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uyulması,
            <br />
            – ZEPHLEX’e ait internet sitesi ile mobil uygulamanın işleyişinin geliştirilmesi,
            <br />
            -Mal/hizmet üretim ve operasyon süreçlerinin yürütülmesi,
            <br />
            -Satın alma ve satış operasyonları ve ilgili (talep, teklif, değerlendirme, sipariş, sözleşme) ile faturalama ve ödeme süreçlerinin yürütülmesi,
            <br />
            -Mal/hizmet satış sonrası destek hizmetlerinin yürütülmesi,
            <br />
            -Kullanıcı deneyimini arttırıcı faaliyetlerin yürütülmesi,
            <br />
            -Sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek önerilmesi ve tanıtılması ürün ve/veya hizmetlerinin satış ve pazarlama süreçlerinin planlanması ve icrası,
            <br />
            -Müşteri memnuniyetinin ölçümlenmesi ve artırılması, müşteri ilişkileri yönetimi süreçlerinin<br />yönetilmesi, şikâyet yönetimi, yeni hizmet ve ürünler ile ilgili görüş ve önerilerinizin alınması, sorun-hata bildirimlerinizin alınması, ürün ve hizmetlere, şikâyet ve taleplerinize yönelik tarafınıza bilgi verilmesi,
            <br />
            -ZEPHLEX tarafından ve kişisel verilerinizin mevzuata uygun olarak aktarıldığı iş ortakları, danışmanlar, tedarikçiler vb üçüncü kişiler tarafından sunulan danışmanlık, hizmet, eğitim, konferans, seminer, yatırımcı buluşması, ürün ve hizmetlerden müşterilerimizin en iyi şekilde faydalanması için çalışmaların yapılması,
            <br />
            -Ürün ve/veya hizmetlerin satış ve pazarlama süreçlerinin, müşteri ilişkilerinin yürütülmesi,
            <br />
            -Reklam, kampanya ve promosyon faaliyetleri kapsamında iletişime geçilmesi, sosyal medya ve diğer elektronik mecralardan yapılacak kampanyalar, abonelikler ve veri analizi uygulamalarının gerçekleştirilmesi,
            <br />
            – Doğrudan ve doğrudan olmayan pazarlama, kişiye özel pazarlama ve yeniden pazarlama faaliyetlerinin yürütülmesi, kişiye özel segmentasyon, hedefleme, analiz ve şirket içi raporlama faaliyetlerinin yürütülmesi, pazar araştırmaları ve anketlerinin planlanması ve icrası,
            <br />
            -Veri sorumlusu olarak Şirketimizin hukuki yükümlülüklerini yerine getirebilmesi amaçları ile işlenir.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</h5>
            <br />
            Kişisel verileriniz yukarıda belirtilen amaçlar kapsamında KVKK’un 8. ve 9. Maddelerinde belirtilen, kişisel veri işleme şartları çerçevesinde; ZEPHLEX’nun hukuki yükümlülüklerini ve ticari faaliyetlerini yerine getirmesi ve tarafınıza ZEPHLEX’in ticari faaliyetleri çerçevesinde mal ve hizmet sunulması için gerekli olan hallerde iş ortaklarımız, ifa yardımcılarımız, danışman firmalarımız, tedarikçilerimiz, doğrudan ve dolaylı hissedarlarımız, iştiraklerimiz ve bağlı ortaklıklarımız, Şirketimiz çalışanları, Şirketimizce destek hizmeti verilen veya hizmet alınan üçüncü kişiler, grup şirketlerimiz, özel kurum ve kuruluşlar ile kamu kurum ve kuruluşlarına aktarılabilecektir. Kişisel verilerinizin paylaşma süreci boyunca ve sonrasında veri güvenliğinin sağlanması için her türlü teknik ve idari tedbir alınmaktadır.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h5>
            <br />
            Kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle ve farklı kanallardan genel müdürlük birimleri, ofisler, çağrı merkezi, internet sitesi, e-posta, dijital mesajlaşma platformları, sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, fiziki ortamda, elektronik ortamda ya da kapalı devre görüntüleme sistemleri vasıtasıyla ve diğer yöntemlerle yasal yollardan elde edilebilmektedir. Ayrıca toplanan kişisel verileriniz, Şirketimiz tarafından verilen hizmet, ürün ya da ticari faaliyete bağlı olarak değişkenlik gösterebilir.
            <br />
            Kişisel verileriniz, iletişim ve bilgi talep formları ile talepte bulunmanız sebebiyle 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 5. Maddesinin 1. Fıkrasında yer alan ilgili kişinin açık rızasının olması veya 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 5. Maddesinin 2. Fıkrasında öngörülen gerekçeler sebebiyle hukuka uygunluk sebeplerine dayalı olarak işlenir. Rızanızın geri alınması halinde ise 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 5. Maddesinin 2. Fıkrasında öngörülen gerekçelerin mevcut olmaması halinde kişisel verileriniz anonimleştirilecektir ve kullanılmayacaktır. Şirket, periyodik olarak verilerin anonimleştirilmesi gerekip gerekmediğini denetlemekte ve re’sen de anonimleşmesi gereken verileri anonimleştirmektedir.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>Veri Sahibi (İlgili Kişi) Olarak Haklarınız</h5>
            <br />
            Veri sahibi olarak Kişisel Verilerin Korunması Kanununun 11. maddesinde belirtilen haklarınıza ilişkin taleplerinizi aşağıda belirtilen yöntemlerle Şirketimize iletmeniz durumunda Şirketimiz niteliğine göre, talebi en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır.
            <br />
            Ancak, işlemin ayrıca bir maliyet gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu’nca belirlenen tarifedeki ücret alınabilecektir.
            <br />
            Verisi işlenen ilgili kişinin KVKK’nın 11. maddesinde belirtilen;
            <br />
            • Kişisel veri işlenip işlenmediğini öğrenme,
            <br />
            • Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
            <br />
            • Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
            <br />
            • Kişisel verilerin aktarıldığı üçüncü kişileri bilme,
            <br />
            • Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
            <br />
            • Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
            <br />
            • İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
            <br />
            • Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme hakları bulunmaktadır.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>Başvurunun Yapılması ve Bilgi Alma Hakkı</h5>
            <br />
            KVK Kanunu’nun 13. maddesinin 1. fıkrası gereğince, aynı kanunun 11. maddesinde belirtilen haklarınızı kullanmak ile ilgili talebinizi, yazılı olarak ya da şirketimize daha önce bildirdiğiniz ve sistemimizde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle
            <br />
            info@zephlex.com adresine veya Çifte Havuzlar Mah. Eski Londra Asfaltı Cad. Kuluçka Merkezi D2 Blok No:151/1F İç Kapı No:203 Esenler, İstanbul adresine iletebilirsiniz. Ayrıca, KVKK’da belirtilen ve belirtilecek diğer yöntemler ile de şirketimize başvuruda bulunabilirsiniz.
            <br />
            (Başvuru Formu)
            <br />
            Başvuruda;
            <br />
            a) Adınız, soyadınız ve başvuru yazılı ise imzanız,
            <br />
            b) Türkiye Cumhuriyeti vatandaşı iseniz T.C. kimlik numarası, yabancı iseniz uyruğunuz, pasaport numaranız veya varsa kimlik numaranız,
            <br />
            c) Tebligata esas yerleşim yeri veya iş yeri adresiniz,
            <br />
            ç) Varsa bildirime esas elektronik posta adresiniz, telefon ve faks numaranız,
            <br />
            d) Talep konunuzun bulunması gerekmektedir. Konuya ilişkin bilgi ve belgeler başvuruya eklenmelidir.
            <br />
            Şirketimiz, bu kapsamdaki taleplerinizi, talebin niteliğine göre en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyet gerektirmesi halinde, Kurulca belirlenen tarifedeki ücret alınabilecektir. Ayrıca başvurularınızın sadece sizlerle ilgili kısmı cevaplanacak olup, eşiniz, yakınınız ya da arkadaşınız hakkında yapılan bir başvuru kabul edilmeyecektir.
            <br />
            <br />
            <h5 style={{ fontWeight: 600, marginBottom: 0 }}>Müşteri Açık Rıza Beyanı</h5>
            <br />
            ZEPHLEX BİLGİ TEKNOLOJİLERİ EĞİTİM VE DANIŞMANLIK A.Ş.nin (“Zephlex”) veri sorumlusu sıfatıyla Kimlik bilgisi, İletişim bilgisi, İşlem bilgisi, İşletim sistemi bilgisi, Mobil ID erişim bilgileri (IP bilgileri) şeklindeki kişisel verilerimin Zephlex ile kurmuş ya da kuracak olduğum sözleşmelerin kurulması ve yerine getirilmesi, Bankanın ürün ve hizmetlerinin sunulabilmesi, bu konuda aldığım/alacağım ürün ve hizmetlere ilişkin iletişim kurulabilmesi, ayrıca izin vermiş olmam durumunda tarafıma yapılacak pazarlama, segmentasyon, hedefleme, analiz faaliyetlerinde kullanılabilmesi, Zephlex’in yasal yükümlülüklerini yerine getirebilmesi ve haklarını kullanabilmesi ve bu amaçlara ilişkin ürün/hizmet geliştirme, modelleme, raporlama çerçevesinde 6698 sayılı Kanun tarafından belirlenen ilke ve güvencelere uygun olarak işlenmesine ve aynı amaç ve koşullarla yasal olarak aktarılması gereken idari ve resmi makamlara, Zephlex’in iş ortakları, ifa yardımcıları, danışman firmaları, tedarikçileri, doğrudan ve dolaylı hissedarları, iştirakleri ve bağlı ortaklıkları, Zephlex çalışanları, Zephlex tarafından hizmet verilen veya Zephlex’in hizmet aldığı üçüncü kişiler, grup şirketleri, özel kurum ve kuruluşlar ile kamu kurum ve kuruluşlarına aktarmasına açık rıza veriyorum.
            <br />
            <br />
            <strong>Müşteri Ad- Soyad:</strong>
            <br />
        </>
}