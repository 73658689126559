import ReactEchartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";

import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

import { BarChart } from "echarts/charts";
import {
  BrushComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from "echarts/components";
import { useSelector } from "react-redux";

import { GaugeChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { THEME_TYPE_DARK } from "../../constants/ThemeSetting";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

export const SentimentPower = ({ power }: { power: number }) => {
  const { themeType } = useSelector(({ settings }: any) => settings);
  const echartsRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    const echartCurrent: any = echartsRef.current;
    if (echartCurrent) {
      echartCurrent.getEchartsInstance().resize();
    }
  }, []);
  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    DataZoomComponent,
    DatasetComponent,
    DataZoomSliderComponent,
    GridSimpleComponent,
    DataZoomInsideComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
    BrushComponent,
    LegendComponent,
    MarkLineComponent,
    CanvasRenderer,
    LineChart,
    GaugeChart,
    BarChart,
  ]);
  return (
    <div style={{ width: "100%" }}>
      <ReactEchartsCore
        ref={echartsRef}
        echarts={echarts}
        style={{
          height: "500px",
          width: "100%",
        }}
        option={{
          color: ["#1d9afb", "rgb(64, 183, 77)", "#ff0000"],
          grid: {
            left: "5%",
            right: "7%",
          },

          series: [
            {
              splitNumber: 4,
              title: {
                color: "#444",
              },
              detail: {
                color: "#444",
              },

              min: 0,
              max: 100,
              name: intl.formatMessage({ id: "page.index.index" }),
              type: "gauge",
              axisLabel: {
                distance: 25,
                fontSize: 17,
              },
              axisLine: {
                lineStyle: {
                  width: 18,
                  color: [
                    [0.25, "#DF3B2F"],
                    [0.5, "#ff9933"],
                    [0.75, "#FDDD60"],
                    [1, "#5CFF92"],
                  ],
                },
              },

              data: [
                {
                  detail: {
                    color: themeType === THEME_TYPE_DARK ? "#aaa" : "#444",
                  },
                  itemStyle: {
                    color: themeType === THEME_TYPE_DARK ? "#aaa" : "#444",
                  },
                  title: {
                    color: themeType === THEME_TYPE_DARK ? "#aaa" : "#444",
                  },
                  value: power,
                },
              ],
            },
          ],
        }}
      />
    </div>
  );
};
