import axios from "axios";
import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { Button, Form, Input, Modal } from "antd";
import PhoneInput from "antd-phone-input";

export const CAMPAIGN_URL = "https://europe-west1-algo-senti.cloudfunctions.net/campaign-handler?campaign=FREE_PACKAGE";

const FORM_STATES = {
  INITIAL: 'initial',
  FORM: 'form',
  SUCCESS: 'success'
}

export const FreePackage = ({ onClose, forceOpen }: { onClose?: () => void, forceOpen?: boolean }) => {
  const [isEligible, setIsEligible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState(FORM_STATES.INITIAL);
  const [canReject, setCanReject] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const [error, setError] = useState(null);

  const rejectCampaign = async () => {
    if (!canReject) return;
    const token = await auth.currentUser?.getIdToken();
    axios.post(CAMPAIGN_URL, {
      action: "reject"
    }, {
      headers: {
        "x-app-uid": token
      }
    });
  }

  const checkEligibility = async () => {
    const token = await auth.currentUser?.getIdToken();
    axios.get(CAMPAIGN_URL, {
      headers: {
        "x-app-uid": token
      }
    }).then((res) => {
      setIsEligible(res.data.status);
      if (!forceOpen && res.data.isRejected) {
        setIsModalOpen(false);
        return;
      } else {
        setIsModalOpen(res.data.status === true);
      }
    });
  };

  useEffect(() => {
    checkEligibility();
  }, []);

  const validator = (_: any, { valid }: { valid: (isStrict?: boolean) => boolean }) => {
    if (valid(true)) return Promise.resolve();
    return Promise.reject("Geçersiz telefon numarası");
  }

  const acceptCampaign = async (values: any) => {

    const token = await auth.currentUser?.getIdToken();
    const phone = "+" + values.phone?.countryCode + " (" + values.phone?.areaCode + ") " + values.phone?.phoneNumber;
    setIsAccepting(true);
    axios.post(CAMPAIGN_URL, {
      action: "accept",
      ...values,
      phone: phone
    }, {
      headers: {
        "x-app-uid": token
      }
    }).then((res) => {
      setCanReject(false);
      setFormState(FORM_STATES.SUCCESS);
    }).catch((err) => {
      console.error(err);
      setError(err.response.data.message ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.");
    }).finally(() => {
      setIsAccepting(false);
    })
  }

  const onFinish = async (values: any) => {
    acceptCampaign(values);
  }


  return (
    <div>
      {isEligible && <Modal
        title={formState === FORM_STATES.SUCCESS || formState === FORM_STATES.FORM ? "Üyelik Kampanyası" : null}

        maskClosable={true}
        okText="Kampanyaya Katıl"
        okButtonProps={{
          style: {
            display: "none"
          },
        }}
        footer={formState === FORM_STATES.SUCCESS ? <Button onClick={() => { setIsModalOpen(false); onClose?.() }}>Kapat</Button> : null
        }
        onOk={() => {
        }}
        onCancel={() => {
          setIsModalOpen(false);
          rejectCampaign();
          onClose?.();
        }}
        open={isModalOpen}

      >
        {formState === FORM_STATES.INITIAL && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}>
          <div style={{ fontSize: "20px", textAlign: "center", padding: 20 }}>
            <b>🎉 Hoş Geldin!<br /> Sentiment Algo Basic Aboneliği 7 Gün Boyunca Ücretsiz Deneyimle! 🚀</b>
          </div>
          <div><b>Borsayı daha iyi anlamak için Sentiment Algo'yu ücretsiz denemeye ne dersin?</b></div>
          <div>Bu pakette seni bekleyen fırsatlar:</div>
          <div style={{ textAlign: "left", padding: 10 }}><ul>
            <li>📊 Günlük piyasa analizleri ve detaylı araştırma raporları</li>
            <li>🔎 En güçlü hisseler, günlük yatırım sepetleri</li>
            <li>📈 Haftalık analizler, akıllı filtreler ve sana özel yatırım mesajları</li>
          </ul></div>
          <div><b>Telefon numaranı ekle, hemen ücretsiz üyeliğini başlat!</b></div>
          <Button onClick={() => setFormState(FORM_STATES.FORM)} style={{ marginTop: 20, marginBottom: 20 }} className="w-full campaign-button" type="primary">Ücretsiz Denemeye Başla</Button>
          <div >Finansal başarın için yanındayız.</div>
          <div><b>Sentiment Algo Ekibi</b></div>
        </div>}

        {
          formState === FORM_STATES.FORM && <div>
            <Form labelCol={{ span: 8 }} style={{ paddingTop: 0 }} onFinish={onFinish} validateTrigger={['']}>
              <Form.Item name="name" label="Adınız">
                <Input required />
              </Form.Item>
              <Form.Item name="surname" label="Soyadınız">
                <Input required />
              </Form.Item>
              <Form.Item name="phone" label="Telefon Numarası" rules={[{ validator }]}>
                <PhoneInput country="tr" enableSearch required />
              </Form.Item>
              {error && <div style={{ color: "red", marginBottom: 20 }}>{error}</div>}
              <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: 20, marginBottom: 0 }}>
                <Button loading={isAccepting} disabled={isAccepting} style={{ marginBottom: 0 }} type="primary" htmlType="submit">Üyeliği Başlat</Button></div>
            </Form>
          </div>
        }

        {
          formState === FORM_STATES.SUCCESS && <div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3, padding: 20 }}>
              <b> 🚀 Üyeliğiniz başarıyla aktif edildi!</b>
            </div>
          </div>
        }
      </Modal >}

    </div >
  );
};
