import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  nsUserSelector,
} from "../appRedux/reducers/sentiment/user";

import asyncComponent from "util/asyncComponent";
import { useAuth } from "../authentication";
import { useAppDispatch } from "../NextApp";
// import { ToastContainer } from "react-toastify";
import { Alarm } from "./Bist/Explore/Alarm";
import "react-toastify/dist/ReactToastify.css";
import { isProd } from "../util/Env";
import Payment from "./Payment";
import axios from "axios";
import { setSymbols } from "../util/ImkbSemboller";
import { Popup } from "../components/Popup";
import { FreePackage } from "../components/FreePackage";

const App = ({ match }) => {
  const { authUser } = useAuth();

  const nsUser = useSelector(nsUserSelector);

  useEffect(() => {
    setSymbols();
  }, []);


  useEffect(() => {
    try {
      if (nsUser?.loaded && authUser) {
        if (process.env.REACT_APP_ZEPHLEX_TRACKER_URL) {
          const time = localStorage.getItem("zephlex_utl");
          let update = true;
          if (time && !isNaN(time)) {
            const diff = new Date().getTime() - parseInt(time);
            if (diff < 1000 * 60 * 5) {
              update = false;
            }
          }

          if (update) {
            localStorage.setItem("zephlex_utl", new Date().getTime());
            axios.get(process.env.REACT_APP_ZEPHLEX_TRACKER_URL, {
              headers: {
                "x-user-id": authUser?.uid,
              },
            });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const noPadding = window.location.pathname.includes("/hub");
  return (
    <div
      className={`gx-main-content-wrapper ${noPadding ? "main-content-no-padding" : ""
        }`}
    >
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboard2"))}
        />
        <Route
          path={`${match.url}index`}
          component={asyncComponent(() => import("./Web"))}
        />
        <Route
          path={`${match.url}bist`}
          component={asyncComponent(() => import("./Bist"))}
        />
        <Route
          path={`${match.url}messages`}
          component={asyncComponent(() => import("./Messages"))}
        />
        <Route
          path={`${match.url}notices`}
          component={asyncComponent(() => import("./Notices"))}
        />
        <Route
          path={`${match.url}help`}
          component={asyncComponent(() => import("./Help"))}
        />
        <Route
          path={`${match.url}account`}
          component={asyncComponent(() => import("./Account"))}
        />
        <Route
          path={`${match.url}bulletins`}
          component={asyncComponent(() => import("./Bulletins"))}
        />
        <Route
          path={`${match.url}dashboard2`}
          component={asyncComponent(() => import("./Dashboard2"))}
        />
        <Route
          path={`${match.url}notifications`}
          component={asyncComponent(() => import("./Notifications"))}
        />
        <Route
          path={`${match.url}alarms`}
          component={asyncComponent(() => import("./Alarms"))}
        />
        <Route
          path={`${match.url}changelog`}
          component={asyncComponent(() => import("./ChangeLog"))}
        />
        <Route
          path={`${match.url}tbot`}
          component={asyncComponent(() => import("./Bott"))}
        />
        <Route
          path={`${match.url}exchanger`}
          component={asyncComponent(() => import("./Exchanger"))}
        />
        <Route path={`${match.url}payment`} component={Payment} />
        <Route
          path={`${match.url}hub`}
          component={asyncComponent(() => import("./Bist/Hub"))}
        />
        <Route
          path={`${match.url}hub-profile`}
          component={asyncComponent(() => import("./Bist/Hub/UserDetailsForm"))}
        />
        <Route
          path={`${match.url}daily-bulletin`}
          component={asyncComponent(() => import("./Bulletins/Daily"))}
        />
        {!isProd() &&
          <Route
            path={`${match.url}sentibot`}
            component={asyncComponent(() => import("./SentiBot"))}
          />
        }
      </Switch>
      {/* <ToastContainer></ToastContainer> */}
      <Popup />
      {<FreePackage />}
      {!isProd() && <Alarm />}
    </div>
  );
};

export default App;
