import React from "react";
import { useSelector } from "react-redux";
import { THEME_TYPE_DARK } from "../../constants/ThemeSetting";

const CircularProgress = ({ className }) => {
  const { themeType } = useSelector(({ settings }) => settings);

  return (
    <div className={`loader ${className}`}>
      <img
        src={themeType === THEME_TYPE_DARK ? "/assets/images/loading-dark.svg" : "/assets/images/loading.svg"}
        alt="loader"
      />
    </div>
  );
};

export default CircularProgress;
