import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { storage } from "../../../firebase";

const DATA_COUNT = 3;
const COLLECTION_NAME = "NS_NEWS";

interface INewsData {
  news: {
    title: string;
    subTitle: string;
    desc: string;
    image: string;
  }[];
  requested: boolean;
  loaded: boolean;
}

const initialState: INewsData = {
  news: [],
  requested: false,
  loaded: false,
};

export function loadNews(firebase: any, count: number = DATA_COUNT) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.News.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.NEWS,
      payload: { requested: true, loaded: false, news: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(count)
      .onSnapshot((querySnapshot: any) => {
        var newsData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");
          newsData.push({
            name: "news",
            title: doc.data().title,
            subTitle: doc.data().subtitle,
            desc: doc.data().desc,
            image: doc.data().image,
            id: doc.id,
            link: doc.data().link,
            dateTime: doc.data().dateTime.toDate(),
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.NEWS,
          payload: {
            news: newsData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function News(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.NEWS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const newsSelector = (state: any) => state.News;
