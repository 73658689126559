import { Step } from 'react-joyride';

interface TourSteps {
  [key: string]: Step[];
}

export const tourSteps: TourSteps = {
  // Dashboard page steps
  '/': [
    {
      target: '.sentiment-chart',
      content: 'Bu grafik, piyasanın genel sentiment durumunu gösterir.',
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '.power-indicator',
      content: 'Sentiment güç göstergesi, piyasadaki momentum gücünü gösterir.',
      placement: 'bottom',
      disableBeacon: true
    },
    // Add more steps for dashboard
  ],
  // Messages page steps
  '/messages': [
    {
      target: '.message-page',
      content: 'Mesajlar bölümüne hoş geldiniz.',
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '.message-list',
      content: 'Burada tüm mesajları görebilirsiniz.',
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '.message-filter',
      content: 'Mesajları filtrelemek için bu bölümü kullanabilirsiniz.',
      placement: 'bottom',
      disableBeacon: true
    }
  ],
  '/bist/lines': [
    {
      target: '.step-lines-tables',
      content: 'Bu bölümde BIST sembollerinin sentiment değerlerini görebilirsiniz.',
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '.step-lines-messages',
      content: 'bu bölümde anlık piyasa mesajlarını görebilirsiniz.',
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '.step-lines-graph',
      content: 'Bu bölümde BIST sembollerinin teknik sentiment grafiklerini görebilirsiniz.',
      placement: 'bottom',
      disableBeacon: true
    },
  ],
  // Add more pages as needed
};
