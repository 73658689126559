import { Button, Form, Input, Row, Col, Grid, Divider, Alert, Checkbox, Popover } from "antd";
import { Link } from "react-router-dom";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { auth } from "../firebase";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { useIntl } from "react-intl";
import { useState } from "react";
import { AgreementContent } from "./AgreenentContent";


const { useBreakpoint } = Grid;

const SignUp = () => {
    const intl = useIntl();
    const { isLoading, error } = useAuth();
    const history = useHistory();
    const screens = useBreakpoint();
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [isKvkkPopoverVisible, setIsKvkkPopoverVisible] = useState(false);

    const [
        createUserWithEmailAndPassword,
        _emailUser,
        _emailUserLoading,
        emailSignupError,
    ] = useCreateUserWithEmailAndPassword(auth);

    const onFinishFailed = (errorInfo) => { };

    const onFinish = async (values) => {
        let userCredential = null;
        try {
            userCredential = await createUserWithEmailAndPassword(
                values.email,
                values.password
            )
        } catch { }
    };

    const getemailSignupErrorText = (code) => {
        switch (code) {
            case "auth/email-already-in-use":
                return "Başka bir e-mail ile kayıt olmayı deneyin";
            case "auth/invalid-email":
                return "Geçersiz e-mail adresi";
            case "auth/weak-password":
                return "Lütfen daha güçlü bir parola belirleyin";
            case "auth/operation-not-allowed":
                return "Kullanıcı hesabı aktif değil";
            default:
                return "Kayıt sırasında hata oluştu";
        }
    };

    const handlePopoverVisibleChange = (visible) => {
        setIsPopoverVisible(visible);
    };

    const handleKvkkPopoverVisibleChange = (visible) => {
        setIsKvkkPopoverVisible(visible);
    };

    const membershipAgreement = (
        <div style={{ position: 'relative', paddingBottom: 20 }}>
            <div style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                padding: '0px 10px',
                borderBottom: '1px solid #f0f0f0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 1
            }}>
                <h5 style={{ fontWeight: 600, margin: 0 }}> Üyelik Sözleşmesi</h5>
                <Button
                    type="text"
                    onClick={() => setIsPopoverVisible(false)}
                    style={{
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 32,
                        height: 32
                    }}
                >
                    ✕
                </Button>
            </div>
            <div style={{
                maxHeight: screens.xxl ? 300 : screens.xl ? 220 : 200,
                overflowY: 'auto',
                padding: '20px 10px',
                textAlign: 'left',
                fontWeight: 500
            }}>
                {AgreementContent.membershipAgreementText}
            </div>
        </div>
    );

    const kvkkContent = (
        <div style={{ position: 'relative', paddingBottom: 20 }}>
            <div style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                padding: '0px 10px',
                borderBottom: '1px solid #f0f0f0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 1
            }}>
                <h5 style={{ fontWeight: 600, margin: 0 }}>KVKK metni</h5>
                <Button
                    type="text"
                    onClick={() => setIsKvkkPopoverVisible(false)}
                    style={{
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 32,
                        height: 32
                    }}
                >
                    ✕
                </Button>
            </div>
            <div style={{
                maxHeight: screens.xxl ? 264 : screens.xl ? 184 : 164,
                overflowY: 'auto',
                padding: '20px 10px',
                textAlign: 'left',
                fontWeight: 500
            }}>
                {AgreementContent.kvkkText}
            </div>
        </div>
    );

    return (
        <div style={{
            backgroundColor: "white",
            minHeight: "100vh",
            height: "100vh",
            width: "100%",
            padding: "0 20px",
            fontFamily: "'Inter', sans-serif",
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
        }}>
            <Row style={{
                minHeight: "100vh",
                height: "100%",
            }}
                align="middle"
            >
                <Col xxl={10} xl={12} lg={12} xs={0}
                    style={{
                        minHeight: "689px",
                        height: "calc(100vh - 10px)",
                        padding: 0
                    }}
                >
                    <img
                        src="/assets/images/layouts/login-bg.svg"
                        alt="Login Background"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: 30,
                        }}
                    />
                </Col>
                <Col xxl={14} xl={12} lg={12} xs={24}
                    style={{
                        padding: screens.lg ? 0 : "20px 0",
                        minHeight: "699px",
                        height: screens.lg ? "699px" : "699px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: screens.lg ? "center" : "center",
                    }}
                >
                    <div
                        style={{
                            width: 315,
                            minHeight: "659px",
                            height: screens.lg ? "699px" : "659px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            textAlign: "center",
                            padding: screens.lg ? "20px 0" : "20px 0 40px 0",
                        }}
                    >

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",

                            }}
                        >
                            <img src="/assets/images/logo.png" style={{ height: 50 }}></img>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                            <div style={{ textAlign: "center", fontWeight: 700, fontSize: 28, lineHeight: "90%", color: "#000000" }}>
                                Yeni hesap oluşturun
                            </div>
                            <div style={{ textAlign: "center", fontWeight: 400, fontSize: 18, lineHeight: "90%", color: "#ABABAB" }}>
                                7 Gün Boyunca Ücretsiz Deneyin
                            </div>
                            <Form
                                initialValues={{ remember: true }}
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >

                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: "Geçersiz e-mail",
                                        },
                                    ]}
                                    style={{ marginTop: 20, marginBottom: 16 }}
                                >
                                    <Input
                                        placeholder={intl.formatMessage({ id: "page.login.email" })} type="email"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: "Lütfen parolanızı girin" }]}
                                    style={{ marginBottom: 16 }}
                                >
                                    <Input.Password placeholder={intl.formatMessage({ id: "page.login.password" })} />
                                </Form.Item>

                                <Form.Item
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error('Lütfen üyelik sözleşmesini okuyup kabul edin')),
                                        },
                                    ]}
                                    style={{ marginBottom: 16, textAlign: "left" }}
                                >
                                    <Checkbox style={{ color: "#ABABAB", lineHeight: "90%", fontSize: 14, fontWeight: 400 }}>
                                        <Popover
                                            content={membershipAgreement}
                                            title={null}
                                            trigger="click"
                                            visible={isPopoverVisible}
                                            onVisibleChange={handlePopoverVisibleChange}
                                            placement="bottom"
                                            showArrow={false}
                                            overlayStyle={{
                                                width: screens.xxl ? 500 : screens.xl ? 450 : screens.lg ? 400 : screens.md ? 400 : screens.sm ? 350 : 300
                                            }}
                                            style={{ marginTop: -40 }}
                                        >
                                            <span style={{ fontWeight: 400, fontSize: 14, lineHeight: "90%", color: "#2800EF", cursor: "pointer" }}>
                                                Üyelik sözleşmesini{" "}
                                            </span>

                                            okudum, kabul ediyorum.
                                        </Popover>
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item
                                    name="kvkk"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error('Lütfen KVKK metnini okuyup kabul edin')),
                                        },
                                    ]}
                                    style={{ marginBottom: 0, display: "flex", textAlign: "left" }}
                                >
                                    <Checkbox style={{ color: "#ABABAB", lineHeight: "90%", fontSize: 14, fontWeight: 400 }}>
                                        <Popover
                                            content={kvkkContent}
                                            title={null}
                                            trigger="click"
                                            visible={isKvkkPopoverVisible}
                                            onVisibleChange={handleKvkkPopoverVisibleChange}
                                            placement="bottom"
                                            showArrow={false}
                                            overlayStyle={{
                                                width: screens.xxl ? 500 : screens.xl ? 450 : screens.lg ? 400 : screens.md ? 400 : screens.sm ? 350 : 300
                                            }}
                                            style={{ marginTop: -40 }}
                                        >
                                            <span style={{ fontWeight: 400, fontSize: 14, lineHeight: "90%", color: "#2800EF", cursor: "pointer" }}>
                                                KVKK{" "}
                                            </span>

                                            metnini okudum, kabul ediyorum.
                                        </Popover>
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        className="gx-mb-0 gx-rounded-button bp-login-button"
                                        htmlType="submit"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            borderRadius: 8,
                                            gap: 12,
                                            height: 40,
                                            fontSize: 16,
                                            lineHeight: "14.4px",
                                            fontWeight: 400,
                                            marginTop: screens.lg ? 48 : 28
                                        }}

                                    >
                                        Kayıt Ol <img height="16px" width="16px" src="/assets/images/right-arrow.svg" />
                                    </Button>
                                </Form.Item>
                                {emailSignupError && emailSignupError.code && (
                                    <Form.Item>
                                        <Alert message={getemailSignupErrorText(emailSignupError.code)} type="warning" showIcon style={{
                                            position: "absolute",
                                            width: 315,
                                            textAlign: "center",
                                        }}
                                        />
                                    </Form.Item>
                                )}

                                <Divider
                                    style={{
                                        borderTopColor: "#ABABAB",
                                        marginTop: screens.lg ? 44 : 34,
                                        marginBottom: screens.lg ? 44 : 34,
                                        color: "#ABABAB",
                                        fontWeight: 400,
                                        fontSize: 16,
                                        lineHeight: "90%"
                                    }}
                                >
                                    <IntlMessages id="page.login.or" />
                                </Divider>
                                <Button
                                    style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "center",
                                        borderRadius: 8,
                                        color: "#9B9B9B",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                        lineHeight: "90%",

                                    }}
                                    onClick={() => {
                                        var provider = new firebase.auth.GoogleAuthProvider();
                                        auth.signInWithPopup(provider).then(() => {
                                            history.push("/dashboard");
                                        });
                                    }}
                                >
                                    <img height="16" src="/assets/images/google.png" />
                                    <IntlMessages id="page.login.signin_with_google" />
                                </Button>

                            </Form>
                        </div>

                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            gap: 12,
                            marginTop: screens.lg ? "" : -20
                        }}>
                            <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "90%", color: "#ABABAB" }}>Zaten hesabın var mı?</div>
                            <Link
                                to="/signin"
                                style={{ fontWeight: 600, fontSize: 16, lineHeight: "90%", color: "#2800EF" }}>Giriş yapın</Link>
                        </div>
                        <AppNotificationContainer loading={isLoading} error={error} />
                    </div>

                </Col>
            </Row >
        </div>
    );
};

export default SignUp;