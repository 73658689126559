import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../NextApp";
import { nsUserSelector } from "../../../appRedux/reducers/sentiment/user";
import {
  IMessage,
  loadMessages,
  messagesSelector,
} from "../../../appRedux/reducers/web/messages";
import { firebaseApp } from "../../../firebase";
import { userSelector } from "../../../appRedux/reducers/web/user";
import {
  hasPermission,
  PERMISSIONS,
} from "../../../appRedux/reducers/sentiment/permissions";
import { getDateStr } from "../../Web/Messages/helpers";
import CustomScrollbars from "../../../util/CustomScrollbars";
import { MessageText } from "../../Messages/MessageText";
import ModalLines from "../../Bist/Signals/modalLines";
import { Tag } from "antd";

export const useLastMessage = () => {
  const dispatch = useAppDispatch();
  const nsUser = useSelector(nsUserSelector);
  const userDetails = useSelector(userSelector);
  const [lastMessage, setLastMessage] = useState<IMessage | null>(null);

  const canShowMessage = (message: IMessage) => {
    if (!message.paid) return true;
    if (!userDetails && !nsUser) return false;
    if (
      (!userDetails || !userDetails.premium) &&
      !hasPermission(nsUser, PERMISSIONS.WEB_ZEPHLEX_PREMIUM_MESSAGE)
    )
      return false;
    return true;
  };

  useEffect(() => {
    dispatch(loadMessages(firebaseApp));
  }, []);

  const messages = useSelector(messagesSelector);

  useEffect(() => {
    if (!messages || !messages.list || messages.list.length == 0) return;
    for (let i = 0; i < messages.list.length; i++) {
      if (canShowMessage(messages.list[i])) {
        setLastMessage(messages.list[i]);
        break;
      }
    }
  }, [messages, nsUser]);

  return lastMessage;
};

export const LatestMessage = () => {
  const lastMessage = useLastMessage();
  const [selectedSymbol, setSelectedSymbol] = useState<string | null>(null);

  if (!lastMessage) return null;

  return (
    <CustomScrollbars
      style={{ height: "calc(100% - 10px)", overflowX: "hidden", overflowY: "hidden" }}
    >
      <div
        style={{
          padding: "5px 20px 20px 20px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 10,
          marginBottom: 20,
        }}
      >
        <div>{getDateStr(lastMessage?.date)}</div>
        <div>
          {(lastMessage?.subcategory || lastMessage?.category) && (
            <Tag color="default" style={{ minWidth: "fit-content" }}>
              {lastMessage?.subcategory && (
                lastMessage.subcategory === "stock" ? "Hisse" :
                  lastMessage.subcategory === "bist" ? "BIST" :
                    lastMessage.subcategory === "news" ? "Haber" :
                      lastMessage.subcategory === "analytics" ? "Analiz" :
                        lastMessage.subcategory
              )}{" "}
              {lastMessage?.category && (
                lastMessage.category === "news" ? "Haber" :
                  lastMessage.category === "analytics" ? "Analiz" :
                    lastMessage.category === "stock" ? "Hisse" :
                      lastMessage.category === "bist" ? "BIST" :
                        lastMessage.category
              )}
            </Tag>
          )}
          {lastMessage?.stock && (
            <Tag color="blue" style={{ minWidth: "fit-content" }}>
              {lastMessage.stock}
            </Tag>
          )}
        </div>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <MessageText
            message={lastMessage}
            setSelectedSymbol={setSelectedSymbol}
            imgSize={20}
            imgBorderRadius={50}
          />
        </div>
      </div>
    </CustomScrollbars>
  );
};
