import { Button, Col, ConfigProvider, Row } from "antd";
import { useEffect, useRef } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";

import { setInitUrl } from "../../appRedux/actions";
import AppLocale from "../../lngProvider";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import MainApp from "./MainApp";

import { datadogRum } from "@datadog/browser-rum";
import "react-toastify/dist/ReactToastify.css";
import {
  loadNsUser,
  nsUserSelector,
} from "../../appRedux/reducers/sentiment/user";
import { useAuth } from "../../authentication";
import CircularProgress from "../../components/CircularProgress";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import { firebaseApp, fireStore } from "../../firebase";
import ForgotPassword from "../ForgotPassword";
import _ from 'lodash';
import { toast } from "react-toastify";
import { isStaging } from "../../util/Env";
import moment from "moment";

datadogRum.init({
  applicationId: "819d526c-d525-4f2b-bb5b-bd39bcae379c",
  clientToken: "pub54076d90f4cb7c42e22231e62a386506",
  site: "datadoghq.com",
  service: "sentiment_algo_com",

  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();
const RestrictedRoute = ({
  component: Component,
  location,
  search,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            search: search,
            state: { from: location },
          }}
        />
      )
    }
  />
);

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

let styleSheetLink = document.createElement("link");
styleSheetLink.type = "text/css";
styleSheetLink.rel = "stylesheet";
document.body.appendChild(styleSheetLink);

const periodConstants = {
  current: "Anlık Bildirimler!",
  hourly: "Saatlik Bildirimler!",
  closeDay: "Gün Sonu Bildirimleri!",
};

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);
  const initURL = useSelector(({ settings }) => settings.initURL);
  const themeColor = useSelector(({ settings }) => settings.themeColor);

  const { authUser, isLoadingUser } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const audioRef = useRef(null);
  const oldNotificationsRef = useRef([]);
  useEffect(() => {
    if (authUser) dispatch(loadNsUser(firebaseApp, authUser.uid));
    if (!authUser?.uid || !isStaging()) return;

    const unSub = fireStore
      .collection('USER_ALARM')
      .doc(authUser.uid)
      .onSnapshot((doc) => {
        if (!doc.exists) return;
        const notifications = doc.data().notifications || [];
        if (oldNotificationsRef.current.length === 0) {
          oldNotificationsRef.current = notifications;
          return;
        }

        const newNotifications = _.differenceWith(
          notifications,
          oldNotificationsRef.current,
          _.isEqual
        );

        if (newNotifications.length > 0) {
          audioRef.current?.play();
          newNotifications.slice(0, 3).forEach((newNotification) => toast.info(
            <>
              <b>{periodConstants[newNotification.period]} ({moment(new Date(newNotification.createdAt.seconds * 1000 + newNotification.createdAt.nanoseconds / 1e6)).format("HH:mm DD.MM.YYYY")})</b><br />
              <span>{newNotification.message}</span>
            </>
            , {
              autoClose: false,
              hideProgressBar: false,
            }))
          if (newNotifications.length > 0) {
            toast.info(
              <Row>
                <Col span={24} style={{ marginBottom: 16 }}>
                  {`${newNotifications.length} yeni bildirim var, `}
                </Col>
                <Col span={24}>
                  <Row justify="end">
                    <Button type="primary" size="small" onClick={() => history.push("/bist/timeline?notification=true")}>Tümünü gör</Button>
                    <Button danger size="small" onClick={() => toast.dismiss()}>Hepsini Kapat</Button>
                  </Row>
                </Col>
              </Row>
              ,
              { autoClose: false, hideProgressBar: false, closeOnClick: false }
            )
          }
        }

        oldNotificationsRef.current = notifications;
      });

    return () => unSub();
  }, [authUser]);

  const nsUser = useSelector(nsUserSelector);

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }

    if (themeColor) {
      styleSheetLink.href = `/css/${themeColor}.min.css`;
    }
  }, [themeColor, isDirectionRTL]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
      styleSheetLink.href = "/css/dark_theme.min.css?v=4";
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
      styleSheetLink.href = "";
    }
  }, [themeType]);

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
  });

  useEffect(() => {
    if (!isLoadingUser) {
      if (!authUser) {
        if (initURL === "/signup")
          history.push("/signup" + (location.search ?? ""));
        else history.push("/signin" + (location.search ?? ""));
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        history.push("/dashboard" + (location.search ?? ""));
      } else {
        history.push(initURL + (location.search ?? ""));
      }
    }
  }, [isLoadingUser, authUser, initURL, history]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];
  const parseSearchParams = (search) => {
    try {
      return new URLSearchParams(search);
    } catch (e) {
      return null;
    }
  };
  return isLoadingUser || (authUser && (!nsUser || !nsUser.loaded)) ? (
    <CircularProgress />
  ) : (
    <ConfigProvider
      locale={currentAppLocale?.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <audio ref={audioRef} src="/assets/music/notification.mp3" preload="auto" />
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <RestrictedRoute
            path={`${match.url}`}
            search={location.search}
            authUser={authUser}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
