import { Button, Form, Input, notification, Row, Col, Checkbox, Grid, Divider, Alert } from "antd";
import firebase from "firebase/app";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Link, useHistory, useLocation } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { auth } from "../firebase";
import "./login.css"

const FormItem = Form.Item;
const { useBreakpoint } = Grid;

const SignIn = () => {
    const intl = useIntl();
    const { isLoading, error, userLogin, setAuthUser } = useAuth();
    const [result, setResult] = useState("");
    const history = useHistory();
    const location = useLocation();
    const screens = useBreakpoint();

    const onFinishFailed = (errorInfo) => { };

    const onFinish = (values) => {
        setResult("");
        userLogin(values, (err) => {
            if (err && err.code) {
                switch (err.code) {
                    case "auth/user-not-found":
                    case "auth/wrong-password":
                        setResult(intl.formatMessage({ id: "page.login.invalid_email_or_password" }));
                        notification.error({
                            message: 'Giriş Başarısız',
                            description: intl.formatMessage({ id: "page.login.invalid_email_or_password" }),
                        });
                        break;
                    case "auth/operation-not-allowed":
                        setResult("Kullanıcı hesabı aktif değil!");
                        notification.error({
                            message: 'Giriş Başarısız',
                            description: 'Kullanıcı hesabı aktif değil!',
                        });
                        break;
                    case "auth/user-disabled":
                        setResult("Kullanıcı hesabı aktif değil!");
                        notification.error({
                            message: 'Giriş Başarısız',
                            description: 'Kullanıcı hesabı aktif değil!',
                        });
                        break;
                    case "auth/invalid-credential":
                        setResult("Kullanıcı bilgileri hatalı!");
                        notification.error({
                            message: 'Giriş Başarısız',
                            description: 'Kullanıcı bilgileri hatalı!',
                        });
                        break;
                    default:
                        setResult("Giriş sırasında hata oluştu!");
                        notification.error({
                            message: 'Giriş Başarısız',
                            description: 'Giriş sırasında hata oluştu!',
                        });
                        break;
                }
            } else {
                notification.success({
                    message: 'Giriş Başarılı',
                    description: 'Giriş işleminiz başarıyla tamamlandı.',
                });
                history.push("/dashboard");
            }
        });
    };

    return (
        <div style={{
            backgroundColor: "white",
            minHeight: "100vh",
            height: "100vh",
            width: "100%",
            padding: "0 20px",
            fontFamily: "'Inter', sans-serif",
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
        }}>
            <Row style={{
                minHeight: "100vh",
                height: "100%",
            }}
                align="middle"
            >
                <Col xxl={10} xl={12} lg={12} xs={0}
                    style={{
                        minHeight: "689px",
                        height: "calc(100vh - 10px)",
                        padding: 0
                    }}
                >
                    <img
                        src="/assets/images/layouts/login-bg.svg"
                        alt="Login Background"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: 30,
                        }}
                    />
                </Col>

                <Col xxl={14} xl={12} lg={12} xs={24}
                    style={{
                        padding: screens.lg ? 0 : "20px 0",
                        minHeight: "689px",
                        height: screens.lg ? "689px" : "689px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: screens.lg ? "center" : "center",

                    }}
                >
                    <div
                        style={{
                            width: 315,
                            minHeight: "649px",
                            height: screens.lg ? "649px" : "649px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            textAlign: "center",
                            padding: screens.lg ? "20px 0" : "20px 0 40px 0",
                        }}
                    >

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",

                            }}
                        >
                            <img src="/assets/images/logo.png" style={{ height: 50 }}></img>
                        </div>

                        <div>
                            <div style={{ textAlign: "center", fontWeight: 700, fontSize: 28, lineHeight: "25.2px", color: "#000000" }}>
                                Hesabınıza giriş yapın
                            </div>
                            <Form
                                initialValues={{ remember: true }}
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                style={{ position: "relative" }}
                            >

                                <Form.Item
                                    rules={[{ required: true, message: intl.formatMessage({ id: "page.login.invalid_email" }) }]}
                                    name="email"
                                    style={{ marginTop: 36, marginBottom: 16 }}
                                >
                                    <Input placeholder={intl.formatMessage({ id: "page.login.email" })} type="email" />
                                </Form.Item>
                                <Form.Item
                                    rules={[{ required: true, message: intl.formatMessage({ id: "page.login.please_enter_your_password" }) }]}
                                    name="password"
                                    style={{ marginBottom: 16 }}
                                >
                                    <Input.Password placeholder={intl.formatMessage({ id: "page.login.password" })} />
                                </Form.Item>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <Checkbox style={{ color: "#ABABAB", lineHeight: "90%", fontSize: 14, fontWeight: 400 }}>Beni hatırla</Checkbox>
                                    </div>
                                    <div>
                                        <Link
                                            to="/forgot-password"
                                            style={{ color: "#ABABAB", lineHeight: "90%", fontSize: 14, fontWeight: 400 }}>
                                            <IntlMessages id="page.login.lost_password" />
                                        </Link>
                                    </div>
                                </div>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        className="gx-mb-0 gx-rounded-button bp-login-button"
                                        htmlType="submit"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            borderRadius: 8,
                                            gap: 12,
                                            height: 40,
                                            fontSize: 16,
                                            lineHeight: "14.4px",
                                            fontWeight: 400,
                                            marginTop: screens.lg ? 48 : 28
                                        }}

                                    >
                                        Giriş Yap <img height="16px" width="16px" src="/assets/images/right-arrow.svg" />
                                    </Button>
                                </Form.Item>
                                {result && result !== "" && (
                                    <Form.Item>
                                        <Alert message={result} type="warning" showIcon style={{
                                            position: "absolute",
                                            width: 315,
                                            textAlign: "center",
                                        }} />

                                    </Form.Item>
                                )}
                                <Divider
                                    style={{
                                        borderTopColor: "#ABABAB",
                                        marginTop: screens.lg ? 44 : 34,
                                        marginBottom: screens.lg ? 44 : 34,
                                        color: "#ABABAB",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                        lineHeight: "90%"
                                    }}
                                >
                                    <IntlMessages id="page.login.or" />
                                </Divider>
                                <Button
                                    style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "center",
                                        borderRadius: 8,
                                        color: "#9B9B9B",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                        lineHeight: "90%",

                                    }}
                                    onClick={() => {
                                        var provider = new firebase.auth.GoogleAuthProvider();
                                        auth.signInWithPopup(provider).then(() => {
                                            notification.success({
                                                message: 'Giriş Başarılı',
                                                description: 'Google ile giriş işleminiz başarıyla tamamlandı.',
                                            });
                                            history.push("/dashboard");
                                        }).catch((err) => {
                                            notification.error({
                                                message: 'Giriş Başarısız',
                                                description: 'Google ile giriş sırasında hata oluştu!',
                                            });
                                        });
                                    }}
                                >
                                    <img height="16" src="/assets/images/google.png" />
                                    <IntlMessages id="page.login.signin_with_google" />
                                </Button>

                            </Form>
                        </div>

                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            gap: 12,
                            marginTop: screens.lg ? "" : -20
                        }}>
                            <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "90%", color: "#ABABAB" }}>Hesabınız yok mu?</div>
                            <Link
                                to={`/signup${location.search ?? ''}`}
                                style={{ fontWeight: 600, fontSize: 16, lineHeight: "90%", color: "#2800EF" }}>Ücretsiz kayıt olun</Link>
                        </div>
                        <AppNotificationContainer loading={isLoading} error={error} />
                    </div>

                </Col>
            </Row >
        </div >
    );
};

export default SignIn;