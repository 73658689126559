export const SYMBOLS = [
    "ACSEL",
    "ADEL",
    "ADESE",
    "AEFES",
    "AFYON",
    "AGESA",
    "AGHOL",
    "AGYO",
    "AHGAZ",
    "AKBNK",
    "AKCNS",
    "AKENR",
    "AKFGY",
    "AKFYE",
    "AKGRT",
    "AKMGY",
    "AKSA",
    "AKSEN",
    "AKSGY",
    "AKSUE",
    "AKYHO",
    "ALARK",
    "ALBRK",
    "ALCAR",
    "ALCTL",
    "ALFAS",
    "ALGYO",
    "ALKA",
    "ALKIM",
    "ALMAD",
    "ANELE",
    "ANGEN",
    "ANHYT",
    "ANSGR",
    "ARASE",
    "ARCLK",
    "ARDYZ",
    "ARENA",
    "ARZUM",
    "ASELS",
    "ASTOR",
    "ASUZU",
    "ATATP",
    "ATEKS",
    "AVGYO",
    "AVHOL",
    "AVOD",
    "AYDEM",
    "AYEN",
    "AYGAZ",
    "AZTEK",
    "BAGFS",
    "BAKAB",
    "BANVT",
    "BARMA",
    "BASGZ",
    "BAYRK",
    "BERA",
    "BEYAZ",
    "BFREN",
    "BIMAS",
    "BIOEN",
    "BIZIM",
    "BJKAS",
    "BLCYT",
    "BMSCH",
    "BMSTL",
    "BNTAS",
    "BOBET",
    "BOSSA",
    "BRISA",
    "BRKVY",
    "BRLSM",
    "BRSAN",
    "BRYAT",
    "BSOKE",
    "BTCIM",
    "BUCIM",
    "BURCE",
    "BURVA",
    "BVSAN",
    "CANTE",
    "CCOLA",
    "CELHA",
    "CEMAS",
    "CEMTS",
    "CEOEM",
    "CIMSA",
    "CLEBI",
    "CMBTN",
    "CONSE",
    "CRDFA",
    "CRFSA",
    "CUSAN",
    "DAGHL",
    "DAGI",
    "DAPGM",
    "DARDL",
    "DENGE",
    "DERHL",
    "DERIM",
    "DESA",
    "DESPC",
    "DEVA",
    "DGATE",
    "DGGYO",
    "DGNMO",
    "DITAS",
    "DMSAS",
    "DNISI",
    "DOAS",
    "DOBUR",
    "DOCO",
    "DOGUB",
    "DOHOL",
    "DOKTA",
    "DURDO",
    "DYOBY",
    "DZGYO",
    "ECILC",
    "ECZYT",
    "EDATA",
    "EDIP",
    "EGEEN",
    "EGEPO",
    "EGGUB",
    "EGPRO",
    "EGSER",
    "EKGYO",
    "ELITE",
    "EMKEL",
    "ENJSA",
    "ENKAI",
    "ENSRI",
    "EPLAS",
    "ERBOS",
    "ERCB",
    "EREGL",
    "ERSU",
    "ESCAR",
    "ESCOM",
    "ESEN",
    "ETILR",
    "EUHOL",
    "EUREN",
    "EYGYO",
    "FADE",
    "FENER",
    "FLAP",
    "FMIZP",
    "FONET",
    "FORMT",
    "FRIGO",
    "FROTO",
    "GARAN",
    "GEDIK",
    "GEDZA",
    "GENIL",
    "GENTS",
    "GEREL",
    "GESAN",
    "GLBMD",
    "GLCVY",
    "GLRYH",
    "GLYHO",
    "GMTAS",
    "GOKNR",
    "GOLTS",
    "GOODY",
    "GOZDE",
    "GRNYO",
    "GRSEL",
    "GSDDE",
    "GSDHO",
    "GSRAY",
    "GUBRF",
    "GWIND",
    "GZNMI",
    "HALKB",
    "HATEK",
    "HDFGS",
    "HEDEF",
    "HEKTS",
    "HKTM",
    "HLGYO",
    "HTTBT",
    "HUBVC",
    "HUNER",
    "HURGZ",
    "ICBCT",
    "SKYLP",
    "IEYHO",
    "IHAAS",
    "IHEVA",
    "IHGZT",
    "IHLAS",
    "IHLGM",
    "IHYAY",
    "IMASM",
    "INDES",
    "INFO",
    "INTEM",
    "INVEO",
    "INVES",
    "IPEKE",
    "ISCTR",
    "ISDMR",
    "ISFIN",
    "ISGSY",
    "ISGYO",
    "ISKPL",
    "ISMEN",
    "ISSEN",
    "ISYAT",
    "ITTFH",
    "IZFAS",
    "IZINV",
    "IZMDC",
    "JANTS",
    "KAPLM",
    "KAREL",
    "KARSN",
    "KARTN",
    "KARYE",
    "KATMR",
    "KCAER",
    "KCHOL",
    "KERVT",
    "KFEIN",
    "KGYO",
    "KIMMR",
    "KLGYO",
    "KLKIM",
    "KLMSN",
    "KLRHO",
    "KLSYN",
    "KMPUR",
    "KNFRT",
    "KONKA",
    "KONTR",
    "KONYA",
    "KORDS",
    "KOZAA",
    "KOZAL",
    "KRDMA",
    "KRDMB",
    "KRDMD",
    "KRGYO",
    "KRONT",
    "KRPLS",
    "KRSTL",
    "KRTEK",
    "KRVGD",
    "KTSKR",
    "KUTPO",
    "KUYAS",
    "KZBGY",
    "LIDER",
    "LIDFA",
    "LINK",
    "LKMNH",
    "LOGO",
    "LUKSK",
    "MAALT",
    "MACKO",
    "MAGEN",
    "MAKIM",
    "MAKTK",
    "MANAS",
    "MARTI",
    "MAVI",
    "MEDTR",
    "MEGAP",
    "MEPET",
    "MERCN",
    "MERIT",
    "MERKO",
    "METRO",
    "METUR",
    "MGROS",
    "MIATK",
    "MIPAZ",
    "MNDRS",
    "MNDTR",
    "MOBTL",
    "MPARK",
    "MRGYO",
    "MRSHL",
    "MSGYO",
    "MTRKS",
    "MZHLD",
    "NATEN",
    "NETAS",
    "NIBAS",
    "NTGAZ",
    "NTHOL",
    "NUGYO",
    "NUHCM",
    "OBASE",
    "ODAS",
    "ONCSM",
    "ORCAY",
    "ORGE",
    "OSMEN",
    "OSTIM",
    "OTKAR",
    "OYAKC",
    "OYLUM",
    "OYYAT",
    "OZGYO",
    "OZKGY",
    "OZRDN",
    "OZSUB",
    "PAGYO",
    "PAMEL",
    "PAPIL",
    "PARSN",
    "PCILT",
    "PEGYO",
    "PEKGY",
    "PENGD",
    "PENTA",
    "PETKM",
    "PETUN",
    "PGSUS",
    "PINSU",
    "PKART",
    "PKENT",
    "PLTUR",
    "PNLSN",
    "PNSUT",
    "POLHO",
    "POLTK",
    "PRDGS",
    "PRKAB",
    "PRKME",
    "PSDTC",
    "PSGYO",
    "QUAGR",
    "RALYH",
    "RAYSG",
    "RNPOL",
    "RTALB",
    "RUBNS",
    "RYGYO",
    "RYSAS",
    "SAFKR",
    "SAHOL",
    "SAMAT",
    "SANFM",
    "SANKO",
    "SARKY",
    "SASA",
    "SAYAS",
    "SDTTR",
    "SEGYO",
    "SEKFK",
    "SEKUR",
    "SELEC",
    "SELVA",
    "SEYKM",
    "SILVR",
    "SISE",
    "SKBNK",
    "SKTAS",
    "SMART",
    "SMRTG",
    "SNGYO",
    "SNICA",
    "SOKE",
    "SOKM",
    "SONME",
    "SRVGY",
    "SUNTK",
    "SUWEN",
    "TATGD",
    "TAVHL",
    "TCELL",
    "TDGYO",
    "TEKTU",
    "TERA",
    "TETMT",
    "TEZOL",
    "TGSAS",
    "THYAO",
    "TKFEN",
    "TKNSA",
    "TLMAN",
    "TMPOL",
    "TMSN",
    "TNZTP",
    "TOASO",
    "TRCAS",
    "TRGYO",
    "TRILC",
    "TSGYO",
    "TSKB",
    "TSPOR",
    "TTKOM",
    "TTRAK",
    "TUCLK",
    "TUKAS",
    "TUPRS",
    "TUREX",
    "TURGG",
    "TURSG",
    "ULKER",
    "ULUFA",
    "ULUSE",
    "ULUUN",
    "UNLU",
    "USAK",
    "VAKBN",
    "VAKFN",
    "VAKKO",
    "VBTYZ",
    "VERTU",
    "VERUS",
    "VESBE",
    "VESTL",
    "VKFYO",
    "VKGYO",
    "YAPRK",
    "YATAS",
    "YAYLA",
    "YEOTK",
    "YESIL",
    "YGGYO",
    "YGYO",
    "YKBNK",
    "YKSLN",
    "YUNSA",
    "YYAPI",
    "YYLGD",
    "ZOREN",
    "ZRGYO"
  ]
  
  export const SYMBOLS_DICT:Record<string,number> = SYMBOLS.reduce((acc:Record<string,number>, val) => {
    acc[val] = 1;
    return acc;
  }, {});
  