export const HELP_MENU = [
    {
        title: "Endeks",
        key: "sidebar.index",
        descKey: "help.index.text.main",
        desc: "Endeks bölümünde Sentiment Algo endeks yönü tahmini yapabileceğiniz veriler yer almaktadır. Ayrıca yine verilerin yorumlamasına dayanan mesajlarda bu kısımda bulunmaktadır.",
        items: [
            "Sentiment Algo Günlük ve Saatlik Sentiment, Anlık Sentiment, Momentum ve Osilatör adını verdiğimiz verileri ve grafikleri üretir.",
            "Günlük Sentiment : Borsanın hava durumudur.",
            "Saatlik Sentiment : Saatlik psikolojik döngüleri – iyiden kötüye kötüden iyiye- gösterir.",
            "Anlık Sentiment : Saatlik ve 15 dk’lık olarak borsanın içinde bulunduğu ruh halinin sayıya dökülmüş halidir.",
            "Osilatör : Büyük hareketleri yakalamak için alıcı satıcı dengelerini gösterir."
        ]
    },
    {
        title: "Hisseler",
        descKey: "help.symbols.text.main",
        key: "sidebar.bist.stocks",
        desc: "Hisse Sentiment veri ve grafiklerini bu bölümde bulacaksınız. Bist 30 için 30 hisse, Bist 70 için 70 hisse, ve Bist 500 için 318 hissenin veri ve grafikleri güncellenmektedir.  Paket içeriğinize göre bu verilere ulaşabilmektedir. Premium Üyeler için Bist 30 ve Bist 70 ilk 15 listeleri kapanış hemen öncesi ve kapanış sonrası güncelleme ile görülebilmektedir. Platin Üyelerimiz 12:30 sonrasında Bist 30 ve Bist 70 listelerinin tamamının güncel hallerine yarım saatte bir erişebilmektedir. Gold Üyelerimiz ise Bist 30- 70 ve Bist 500 listelerinin tamamına 12:30 sonrası 10 dk’da bir güncellenecek şekilde ulaşmaktadır. ",
        items: [
            "Basic; Bist 30 ve Bist 70 listeleri kapanış sonrası güncellenmektedir.",
            "Pro; 10:30 sonrasında Bist 30 ve Bist 70 listeleri 30 dk'da bir güncellenmektedir.",
            "Pro Plus; Bist 30, Bist 70 ve Yıldız Pazar listeleri 10:30 sonrası 10dk'da bir güncellenmektedir.",
            "Gold;Bist 30, Bist 70 ve Yıldız Pazar ve Ana Pazar listeleri 10:30 sonrası 10dk'da bir güncellenmektedir."
        ]
    },
    {
        title: "Akıllı Filtre",
        descKey: "help.follower.text.main",
        key: "sidebar.bist.smartFilter",
    },
    {
        title: "Hisse Bulucu",
        descKey: "help.explorer.text.main",
        key: "sidebar.bist.stockFinder",
        desc: "Kaşif özelliği sayesinde filtreler ile aradığınız özellikte hisseleri sizin için biz taramaktayız. Siz sadece istediğiniz özellikleri seçin ve gerisini bize bırakın."
    },
    {
        descKey: "help.first10.text.main",
        title: "İlk 10 Listeleri",
        key: "sidebar.bist.first_list",

        desc: "İlk 10 listeleri ile gün içinde de, Hisse Sentiment ilk 10’a girme potansiyeli olan hisseleri ve çıkma ihtimali olan hisseleri takip edebileceksiniz. Bu kullanımı kolay özellik sayesinde paket içeriğinize göre gün içinde de Hisse Sentiment’in keyfini sürün. "
    },
    {
        descKey: "help.last10.text.main",
        key: "sidebar.bist.last_list",
        title: "Son 10 Listeleri",
        desc: "Son 10 listeleri Sentiment derecesi en düşük hisseleri içerir. Buradan yukarı toparlamalar ve son 10’dan çıkarak yükselenler, dip tepkisi verebilecek hisseleri görmek için rehber niteliğinde olabilir."
    },
    {
        title: "Sinyal Merkezi",
        descKey: "help.symbol_signal.text.main",
        key: "sidebar.bist.signalCenter",
    },
    {
        title: "Takip Listelerim",
        descKey: "help.follower_list.text.main",
        key: "sidebar.bist.follower_list",
    },
    {
        descKey: "help.trade_first_10.text.main",
        key: "help.trade_history_first_10",
        title: "İlk 10 İşlem Geçmişi",
        desc: "Artık ilk 10 listelerine giren hisseleri giriş ve çıkış tarihlerini, girdikten sonra yaptıkları performans takip etmek çok kolay. İşlem geçmişi kısmını kullanarak kolay takibini avantajından faydalanın."
    },
    {
        descKey: "help.trade_last_10.text.main",
        key: "help.trade_history_last_10",
        title: "Son 10 İşlem Geçmişi",
        desc: "Artık ilk 10 listelerine giren hisseleri giriş ve çıkış tarihlerini, girdikten sonra yaptıkları performans takip etmek çok kolay. İşlem geçmişi kısmını kullanarak kolay takibini avantajından faydalanın."
    },
    {
        descKey: "help.profit.text.main",
        key: "sidebar.bist.profit_charts",
        title: "Getiri Grafikleri",
        desc: "Getiri grafikleri ile her zaman bir performans ölçütü olarak ilk 10 listelerini takip edebilirsiniz. Bu başarıya siz de ortak olun. "
    },
]